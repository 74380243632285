.bar {
  width: 10rem;
  background-color: #4874a8;
  height: 100vh;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;

  @media only screen and (max-width: 800px) {
    width: 6rem;
  }
}

.logo {
  width: 100%;
  max-height: 4rem;
  padding: 1rem;
}
.content {
  flex: 1;
  overflow-x: auto;
}

.actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  svg {
    margin: 0.5rem;
    color: rgba(255, 255, 255, 0.6);
    transition: all 0.3s ease;
    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}
