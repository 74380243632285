.counter {
  background-color: #fefefe;
  padding: 1rem 1.5rem;
  margin: 1.5rem 0;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &:hover {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
}

.count {
  flex: 1;
  text-align: right;
  @media only screen and (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

.input {
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  padding: 0.4rem;
  border: 1px solid transparent;
  border-radius: 0.3rem;
  width: 50%;
  &:hover {
    background-color: ddd;
    border: 1px solid #4874a8;
  }
  @media only screen and (max-width: 800px) {
    font-size: 1.1rem;
    width: 80%;
  }
}

.counterInput {
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  padding: 0.4rem;
  border: 1px solid transparent;
  width: 5rem;
  border-radius: 0.3rem;
  text-align: center;
  &:hover {
    background-color: ddd;
    border: 1px solid #4874a8;
  }
  @media only screen and (max-width: 800px) {
    text-align: right;
  }
}
