.content {
  margin: 1rem;
}

.inputheader {
  width: 100%;
  font-size: 3rem;
  border: none;
  background-color: transparent;
  font-family: Roboto;
  &:focus,
  &:active {
    border: none;
  }
}

.animation {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header {
  display: flex;
  align-items: center;
  input {
    flex: 2;
  }
  .total {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .controls {
    width: 20rem;

    text-align: right;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    gap: 0.5rem;
    input {
      flex: 1;
      text-align: center;
    }
    .controls {
      flex: 1;
      text-align: center;
    }
  }
}

.scrollContainer {
  max-height: calc(100vh - 120px);
  overflow-x: auto;
  padding: 1rem;
}
